import React, { Component } from "react";
//import { render } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  UPDATE_TEXTAREA_ATTRIBUTES,
  SHOULD_TEXT_SEND,
  UPDATE_MESSAGE_BODY,
  HAS_ASSOCIATE_ID
} from "redux/actions/types";
import {
  mainAction,
  sendMessageForWeb,
  uploadOutgoingAttachment,
} from "../redux/actions/interactionsActions";
import MsgNav from "./Message/MsgAreaButtonBar";
import MediaQuery from "react-responsive";
import Modal from "./CustomerSearch/Modal";
import {
  formatBytes,
  validateFile,
  shortenFileName,
} from "../utils/fileUtility";
import { wsReadyState } from "../utils/apiGatewayWebsocketHandler";

class SendMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disclaimer:
        "To protect sensitive personal information, do not include  full SSN, TIN, SIN, DL#, financial account numbers, credit/debit card numbers, PHI or any medical information",
      errorMessage: "Error message displays here.",
      viewport: {
        left: window.pageXOffset,
        top: window.pageYOffset,
        width: window.innerWidth || document.documentElement.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight,
      },
      keyboard_is_on: false,
      attachmentFile: null,
      force_disable_send: false,
    };
    this.clearAttachments = this.clearAttachments.bind(this);
    this.displayAttachmentPreview = this.displayAttachmentPreview.bind(this);
    this.uploadFileRef = React.createRef();
  }

  componentDidMount() {
    if (window.oneX) {
      window.oneX.Icons.init();
    }
    this.props.actions.mainAction(UPDATE_TEXTAREA_ATTRIBUTES, {
      start: 0,
      end: 0,
    });
    this.getViewport();
  }

  componentDidUpdate(prevProps) {
    if (this.props.interactionId !== prevProps.interactionId) {
      this.clearAttachments();
    }

    if (this.props.hasAssociateId && !prevProps.hasAssociateId) {
      this.props.actions.sendMessageForWeb(
        this.props.webSocketMessage,
        this.props.officeId,
        this.props.officePhoneNumber.trim(),
        this.props.userLoggedInName,
        this.props.selectedTemplate || undefined,
        this.props.currentInteraction.messageSenderId ? this.props.currentInteraction.messageSenderId : this.props.interactionsReducer.loggedInAssociateId
      );

      this.setState({
        force_disable_send: false,
      });

      this.props.actions.mainAction("SELECTED_TEMPLATE", {
        "selectedTemplate" : ""
      });
      
    }
    this.keyboardCheck();

    if (window.oneX) {
      window.oneX.Icons.init();
    }
  }

  clearAttachments() {
    this.setState({
      attachmentFile: null,
    });
    this.uploadFileRef.current.value = "";
  }

  keyboardCheck() {
    const _keyboard_is_on =
      this.state.viewport.width === window.innerWidth &&
      this.state.viewport.height > window.innerHeight;
    if (_keyboard_is_on !== this.state.keyboard_is_on) {
      this.setState({ keyboard_is_on: _keyboard_is_on });
    }
    // document.addEventListener("focusout", function (e) {
    //   window.scrollTo(0, 0);
    // });
  }

  onKeyUpClick() {
    const _pos = this.getCursorPos(this.refs.textarea);
    this.props.actions.mainAction(UPDATE_TEXTAREA_ATTRIBUTES, {
      start: _pos.start,
      end: _pos.end,
    });
    this.keyboardCheck();
  }

  setTextAreaCursorPos(start, end) {
    this.setCursorPos(this.refs.textarea, start, end);
    this.props.actions.mainAction(UPDATE_TEXTAREA_ATTRIBUTES, {
      start: start,
      end: end,
    });
  }

  getViewport() {
    // http://www.quirksmode.org/mobile/tableViewport.html
    let _viewport = {
      left: window.pageXOffset,
      top: window.pageYOffset,
      width: window.innerWidth || document.documentElement.clientWidth,
      height: window.innerHeight || document.documentElement.clientHeight,
    };
    if (window.visualViewport && /Android/.test(navigator.userAgent)) {
      // https://developers.google.com/web/updates/2017/09/visual-viewport-api
      // Note on desktop Chrome the viewport subtracts scrollbar widths so is not same as window.innerWidth/innerHeight
      _viewport = {
        left: visualViewport.pageLeft,
        top: visualViewport.pageTop,
        width: visualViewport.width,
        height: visualViewport.height,
      };
    }
    if (/iPod|iPhone|iPad/.test(navigator.platform)) {
      // iOS *lies* about viewport size when keyboard is visible.
      // See http://stackoverflow.com/questions/2593139/ipad-web-app-detect-virtual-keyboard-using-javascript-in-safari
      // Input focus/blur can indicate, also scrollTop: (May want to add a check for input type)
      _viewport = {
        left: _viewport.left,
        top: _viewport.top,
        width: _viewport.width,
        height:
          _viewport.height * (_viewport.height > _viewport.width ? 0.66 : 0.45), // Fudge factor to allow for keyboard on iPad
      };
    }
    this.setState({ viewport: _viewport });
  }

  getCursorPos(input) {
    if ("selectionStart" in input && document.activeElement === input) {
      return {
        start: input.selectionStart,
        end: input.selectionEnd,
      };
    } else if (input.createTextRange) {
      var sel = document.selection.createRange();
      if (sel.parentElement() === input) {
        var rng = input.createTextRange();
        rng.moveToBookmark(sel.getBookmark());
        for (
          var len = 0;
          rng.compareEndPoints("EndToStart", rng) > 0;
          rng.moveEnd("character", -1)
        ) {
          len++;
        }
        rng.setEndPoint("StartToStart", input.createTextRange());
        for (
          var pos = { start: 0, end: len };
          rng.compareEndPoints("EndToStart", rng) > 0;
          rng.moveEnd("character", -1)
        ) {
          pos.start++;
          pos.end++;
        }
        return pos;
      }
    }
    return -1;
  }

  setCursorPos(input, start, end) {
    if (arguments.length < 3) end = start;
    if ("selectionStart" in input) {
      setTimeout(function () {
        input.selectionStart = start;
        input.selectionEnd = end;
      }, 1);
    } else if (input.createTextRange) {
      var rng = input.createTextRange();
      rng.moveStart("character", start);
      rng.collapse();
      rng.moveEnd("character", end - start);
      rng.select();
    }
    return true;
  }

  async onClickSend() {
    await this.setState({
      force_disable_send: true,
    });
    const sendButton = this.refs.sendMessageButton;
    let isAssociateIdMissing = false;

    sendButton.classList.add("loading-spinner");
    this.props.currentInteraction.messageDeliveryStatus = "sending";
    this.props.currentInteraction.messageDeliveryErrorType = "";
    this.props.currentInteraction.messageDeliveryErrorDetails = "";

    //Handling missing Owner Agent Associate Id in websocketJSON since backend does not handle yet.
    if (this.props.webSocketMessage.ownerAgent.associateID === "") {
      this.props.currentInteraction.messageDeliveryStatus = "failed";
      this.props.currentInteraction.messageDeliveryErrorType =
        ": Missing Data. Submit service ticket.";
        isAssociateIdMissing = true;
    }

    if (this.state.attachmentFile && this.state.attachmentFile.isValid) {
      try {
        await this.props.actions.uploadOutgoingAttachment(
          this.props.token,
          this.props.officeId,
          this.state.attachmentFile,
          this.props.currentInteraction.clientId
        );
      } catch (error) {
        sendButton.classList.remove("loading-spinner");
        this.props.onAttachmentError(error);
        return;
      }
    }

    if (
      this.props.webSocketMessage.message.attachments &&
      this.props.webSocketMessage.message.body === ""
    ) {
      // inject info about number of attachments into body
      this.props.actions.mainAction(UPDATE_MESSAGE_BODY, null);
    }

    this.clearAttachments();
    
    this.props.actions.mainAction(HAS_ASSOCIATE_ID, isAssociateIdMissing);

    this.props.actions.mainAction(SHOULD_TEXT_SEND, true);
    setTimeout(function () {
      sendButton.classList.remove("loading-spinner");
      //Post A Message to the iosListener (iOS Native)
      //iOSListener.publish("message_sent");
    }, 3000);
  }

  disableSendButton() {
    if (
      this.props.currentInteraction.doNotText ||
      this.props.currentInteraction.messageDeliveryStatus === "sending" ||
      this.props.currentInteraction.clientId === "MORETHAN100" ||
      this.props.currentInteraction.clientType === "prospect" ||
      this.props.currentInteraction.clientType === "multi-match" ||
      this.props.currentInteraction.casl ||
      this.props.currentInteraction.virtualAssistantActive ||
      this.props.webSocketMessage.destination[0].data.phone === "" ||
      (this.props.currentInteraction.ecrmMobile &&
        this.props.currentInteraction.ecrmMobile !== "" &&
        this.props.webSocketMessage.destination[0].data.phone !==
          this.props.currentInteraction.ecrmMobile)
    ) {
      return true;
    }

    if (this.state.attachmentFile) {
      // disable if attached file is invalid
      return !this.state.attachmentFile.isValid;
    } else if (this.props.messageBody === "") {
      return true;
    }
    return false;
  }

  displayAttachmentPreview() {
    if (this.state.attachmentFile)
      return (
        <div className="preview-pane">
          <div className="preview-box">
            {this.state.attachmentFile.type.match(/image\//) ? (
              <img
                src={URL.createObjectURL(this.state.attachmentFile)}
                height="34"
                alt="preview"
              />
            ) : (
              <div className="-oneX-icon-container -oneX-icon--interactive">
                <div className="-oneX-icon" data-icon="documents_24"></div>
              </div>
            )}
            <div className="file-info">
              <span>
                {this.state.attachmentFile.name.length > 15
                  ? shortenFileName(this.state.attachmentFile.name)
                  : this.state.attachmentFile.name}
              </span>
              <span className="file-size">
                {formatBytes(this.state.attachmentFile.size)}
              </span>
            </div>
            <div className="-oneX-icon-container -oneX-icon--interactive">
              <div
                className="-oneX-icon"
                data-icon="trash_24"
                onClick={this.clearAttachments}></div>
            </div>
          </div>
          {this.state.attachmentFile.isValid ? (
            <></>
          ) : (
            <div className="-oneX-icon-container -oneX-icon--interactive">
              <div
                className="-oneX-icon  -oneX-icon--variant1"
                data-icon="alert_24"></div>
            </div>
          )}
        </div>
      );
  }

  displayAttachmentModal(event, currentInteractionId) {
    event.stopPropagation();
    if (event.key === "Enter" || event.key === " " || event.type === "click") {
      window.oneX.Modal.showModal(
        this,
        "oneX-modal-attach-" + currentInteractionId
      );
    }
  }

  render() {
    return (
      <>
        <section id="send" className="send-message -oneX">
          <div className="-oneX-container">
            <div
              className={
                "-oneX-row send-message-pane" +
                (this.state.keyboard_is_on ? " keyboard" : "")
              }
              data-mobile-keyboard={this.state.keyboard_is_on}>
              <div className="-oneX-col">
                {/* | Mobile View Port | */}
                <MediaQuery maxWidth={990}>
                  <div className="-oneX-row">
                    <div className="-oneX-col">
                      <label htmlFor="conTA" className="hide-offscreen">
                        Text message to send
                      </label>
                      <textarea
                        id="conTA"
                        ref={"textarea"}
                        className="-oneX-textfield--conversational-input"
                        value={this.props.messageBody}
                        placeholder={this.state.disclaimer}
                        onKeyUp={(e) => this.onKeyUpClick()}
                        onClick={(e) => this.onKeyUpClick()}
                        onChange={(e) =>
                          this.props.actions.mainAction(
                            "UPDATE_CHAT",
                            e.target.value
                          )
                        }></textarea>
                      <div className="-oneX-widget__err-text">
                        {this.state.errorMessage}
                      </div>
                      <div className="send-message-action">
                        <button
                          id="sendMsg"
                          ref={"sendMessageButton"}
                          className="-oneX-btn-primary"
                          disabled={
                            this.state.force_disable_send ||
                            this.disableSendButton()
                          }
                          onClick={(e) => this.onClickSend()}>
                          SEND
                        </button>
                      </div>
                    </div>
                  </div>
                </MediaQuery>
                {/* | Desktop View Port | */}
                <MediaQuery minWidth={991}>
                  <div className="-oneX-row">
                    <div className="-oneX-col">
                      <label
                        id="textAreaLabel"
                        htmlFor="conTA"
                        className="hide-offscreen">
                        Text message to send
                      </label>
                      {this.props.protectedPersonIndicator && (
                        <label
                          id="PPI"
                          className="-oneX-labels associate-initial-section protected-person-label">
                          Protected Person
                        </label>
                      )}
                      <div className="textbox">
                        <textarea
                          id="conTA"
                          aria-labelledby="textAreaLabel clientName PPI"
                          ref={"textarea"}
                          className="-oneX-textfield--conversational-input"
                          value={this.props.messageBody}
                          placeholder={this.state.disclaimer}
                          onKeyUp={(e) => this.onKeyUpClick()}
                          onClick={(e) => this.onKeyUpClick()}
                          onChange={(e) =>
                            this.props.actions.mainAction(
                              "UPDATE_CHAT",
                              e.target.value
                            )
                          }></textarea>
                        <div className="bottom-panel">
                          {this.displayAttachmentPreview()}
                          <div className="-oneX-icon-container -oneX-icon--interactive">
                            <div
                              className="-oneX-icon attachment-icon"
                              data-icon="attachment_24"
                              aria-label="Attach a file"
                              role="button"
                              title={
                                this.state.attachmentFile
                                  ? "1 file attached, click to replace the attached file"
                                  : "Click to attach a file"
                              }
                              tabIndex="0"
                              onClick={(event) =>
                                this.displayAttachmentModal(
                                  event,
                                  this.props.currentInteraction.interactionId
                                )
                              }
                              onKeyDown={(event) =>
                                this.displayAttachmentModal(
                                  event,
                                  this.props.currentInteraction.interactionId
                                )
                              }></div>
                          </div>
                        </div>
                      </div>
                      <div className="-oneX-widget__err-text">
                        {this.state.errorMessage}
                      </div>
                    </div>
                    <div className="-oneX-col-2" style={{ paddingLeft: "0" }}>
                      <div className="send-message-action">
                        <button
                          id="sendMsg"
                          ref={"sendMessageButton"}
                          className="-oneX-btn-primary"
                          disabled={this.disableSendButton()}
                          onClick={(e) => this.onClickSend()}>
                          SEND
                        </button>
                      </div>
                    </div>
                  </div>
                </MediaQuery>
                <div className="bottom-nav">
                  <MsgNav currentInteraction={this.props.currentInteraction} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          ref={this.uploadFileRef}
          attachFile={(e) => {
            const file = e.target.files[0];
            file.isValid = validateFile(file);
            if (!file.isValid) {
              this.props.onAttachmentError("invalid_file");
            }
            window.oneX.Modal.hideModal();
            this.setState({
              attachmentFile: file,
            });
          }}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    messageBody: state.interactionsReducer.currentInteraction.messageBody,
    textMessageBox: state.textMessageBox,
    token: state.authReducer.token,
    //agentId: state.authReducer.associateId,
    officeId: state.userInfoReducer.officeAssociateId,
    hasAssociateId: state.interactionsReducer.currentInteraction.hasAssociateId,
    webSocketMessage:
      state.interactionsReducer.currentInteraction.webSocketJSON,
    interactionId: state.interactionsReducer.currentInteraction.interactionId,
    currentInteraction: state.interactionsReducer.currentInteraction,
    customerName: state.interactionsReducer.currentInteraction.customerName,
    protectedPersonIndicator:
      state.interactionsReducer.currentInteraction.protectedPersonIndicator,
    virtualAssistantActive:
      state.interactionsReducer.currentInteraction.virtualAssistantActive,
    shouldTextSend: state.interactionsReducer.shouldTextSend,
    interactionsReducer: state.interactionsReducer,
    legacyWebSocketReadyState: state.webSocketReducer.legacyReadyState,
    apiGatewayWebSocketReadyState: state.webSocketReducer.apiGatewayReadyState,
    userLoggedInName: state.userInfoReducer.loggedInAssociateName,
    selectedTemplate: state.customTemplatesReducer.selectedTemplate,
    officePhoneNumber: state.officeSettingsReducer.officePhone.number,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        sendMessageForWeb,
        uploadOutgoingAttachment,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
