import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import ReconnectingWebSocket from 'reconnecting-websocket';
import {
  mainAction,
  patchInteractionReadSetting,
  getClientInfoForNewClientInteraction,
  getMMInfoForNewClientInteraction,
  logUIErrors,
} from "redux/actions/interactionsActions";
import { SFNotifications } from "utils/notifications";
import { getAssociateInformation } from "./redux/actions/userInfoActions";

import {
  UPDATE_INTERACTIONS,
  GET_OFFICE_PREFERENCES,
  GET_INTERACTIONS,
  GET_CUSTOMER_PREFERENCES,
  MESSAGE_DELIVERY_STATUS,
  UPDATE_MESSAGE_DELIVERY_STATUS,
  UPDATE_DO_NOT_TEXT_STATUS_BAR,
  CHANGE_FILTER_OPTIONS,
  UPDATE_CHAT_LOG,
  SET_AZURE_TOKEN,
  HIDE_INTERACTION,
  UNHIDE_INTERACTION,
  SET_CONNECTED_TO_API_GATEWAY_WEBSOCKET,
  ASSIGN_INTERACTION,
  UNASSIGN_INTERACTION,
  ADD_CUSTOM_TEXT_TEMPLATES,
  UPDATE_CUSTOM_TEXT_TEMPLATES,
  DELETE_CUSTOM_TEXT_TEMPLATES,
  UPDATE_TEMPLATE_STATUS,
  SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET,
} from "redux/actions/types.js";

import "./scss/main.scss";

import Home from "./pages/Home";
import Search from "./pages/Search";
import Gateway from "./pages/gateway";
import Moa from "./components/Moa";
import GenericError from "./pages/genericError";
import Signup from "./pages/signup";
import SFConnectLogo from "./components/Common/sf-logo";
import { sfc_routes } from "./utils/general";

import Header from "./global/components/Header";
import { legacyWebsocketHandler } from "./utils/legacyWebsocketHandler";
import {
  apiGatewayWebsocketHandler,
  wsReadyState,
} from "./utils/apiGatewayWebsocketHandler";

import {
  apiAppsyncWebsocketHandler,
} from "./utils/apiAppsyncWebsocketHandler";

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

//Get the alias from the token
const parseJwtTokenAndExtractAliasId = (azureToken) =>{  
  const decodedToken = parseJwt(azureToken);  
  let emailAddress = decodedToken.preferred_username;
  let emailArray = emailAddress.split("@");
  let firstHalf = emailArray[0];
  let nameArray = firstHalf.split(".");
  return nameArray[nameArray.length - 1];
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      azureToken: localStorage.getItem("token"),
      hasAzureToken: true,
      loading: true,
    };
  }

  // If the token from local storage is not expired then set azure token to Redux storage and
  // restart the token check loop.
  setAzureToken = (value) => {
    if (value) {
      const decodedJwt = parseJwt(value);
      const exp = decodedJwt.exp * 1000;
      if (exp > Date.now()) {
        this.props.actions.mainAction(SET_AZURE_TOKEN, value);
        this.refreshToken(value);
      }
    }
  };

  // Get token from local storage and check if it is equals to the previous one.
  // If it is not, store the new token on Redux and refresh the websocket connection.
  updateAzureTokenFromStorage(previousToken) {
    const currentToken = localStorage.getItem("token");
    this.setState({
      loading: false,
      azureToken: currentToken,
      hasAzureToken: true,
    });
    this.setAzureToken(this.state.azureToken);
    // If this is the first token then get associate information and open websocket connection.
    if (!previousToken && currentToken) {      
      const userAliasId = parseJwtTokenAndExtractAliasId(currentToken);
      this.props.actions.getAssociateInformation(userAliasId);
      legacyWebsocketHandler.connect(process.env.REACT_APP_AWS_WSS_URL, [
        "X-SF_AZURE_TOKEN",
        this.state.azureToken,
      ]);
    }
    // If it is a new token then refresh websocket connection.
    else if (previousToken && currentToken !== previousToken) {
      legacyWebsocketHandler.refresh([
        "X-SF_AZURE_TOKEN",
        this.state.azureToken,
      ]);
      apiGatewayWebsocketHandler.resetToken(this.state.azureToken);
    }
  }

  // Check for new token every minute.
  refreshToken(token) {
    const waitAMinute = 60 * 1000;
    setTimeout(() => {
      this.updateAzureTokenFromStorage(token);
    }, waitAMinute);
  }

  componentDidMount() {
    // Wait one second and update redux with the token from local storage
    setTimeout(() => {
      this.updateAzureTokenFromStorage();
    }, 1000);
  }

  componentDidUpdate() {
    if (
      !this.props.connectedToAPIGatewayWebSocket &&
      this.props.associateId &&
      this.props.loggedInAssociateId
    ) {
      apiGatewayWebsocketHandler.connect(
        process.env.REACT_APP_API_GATEWAY_WEBSOCKET_API,
        this.state.azureToken,
        this.props.associateId,
        this.props.loggedInAssociateId
      );

      this.props.actions.mainAction(SET_CONNECTED_TO_API_GATEWAY_WEBSOCKET);
    }

    if (
      !this.props.connectedToAPIAppsyncWebSocket &&
      this.props.associateId &&
      this.props.loggedInAssociateId
    ) {
        apiAppsyncWebsocketHandler.connect(
        process.env.REACT_APP_API_APPSYNC_WEBSOCKET_API,
        this.props.associateId,
        this.props.loggedInAssociateId
      );

      this.props.actions.mainAction(SET_CONNECTED_TO_API_APPSYNC_WEBSOCKET,true);
    }

    if (
      this.props.legacyWebSocket &&
      this.props.legacyWebSocket.readyState === wsReadyState.OPEN
    ) {
      this.props.legacyWebSocket.onmessage = (payload) => {
        let response = JSON.parse(payload.data);
        if (!response.replay) {
          switch (response.type) {
            case "clientPreference": {
              this.props.actions.mainAction(GET_CUSTOMER_PREFERENCES, response);
              //To show Do not Text status bar if it changes in a WS push
              this.props.actions.mainAction(
                UPDATE_DO_NOT_TEXT_STATUS_BAR,
                response
              );
              break;
            }
            case "interaction_delete": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId ===
                  response.data.interaction.Item.ownerAgent.associateID
              ) {
                this.props.actions.mainAction(GET_INTERACTIONS, {
                  response: response,
                  currentOfficeMembers: this.props.currentOfficeMembers,
                });
                this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                  endlessScroll: true,
                });
              }
              break;
            }
            case "interaction": {
              //if check for restricting read/unread interactions to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                this.props.actions.mainAction(GET_INTERACTIONS, {
                  response: response,
                  currentOfficeMembers: this.props.currentOfficeMembers,
                });
                //If check New filter being selected and the incoming WS message is stating it is read, which means user clicked on a New message.
                //In order to avoid the interaction from disappearing from the New list, we remove the New filter selection so user can reply to the message without disruption.
                if (
                  this.props.filterOptions.MessageStatus === "Unread" &&
                  this.props.currentInteraction.isMessageViewed
                ) {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    name: "MessageStatus",
                    value: " ",
                    isNewlyRead: true,
                    endlessScroll: true,
                  });
                } else {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
              }
              break;
            }
            case "message": {
              //if check for restricting messages to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                //For customer-initiated messages, an extra call to get the customer detail needs to be made.
                let isNewInteraction = this.props.interactions.filter(
                  (interaction) =>
                    interaction.interactionId === response.data.interactionID ||
                    interaction.clientId === response.data.ownerClient.clientID
                );
                if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "client"
                ) {
                  this.props.actions.getClientInfoForNewClientInteraction(
                    response
                  );
                } else if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "multi-match"
                ) {
                  this.props.actions.getMMInfoForNewClientInteraction(                    
                    response,
                  );
                } else {
                  this.props.actions.mainAction(UPDATE_INTERACTIONS, {
                    data: response.data,
                  });
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
                if (response.data.sender.type !== "associate") {
                  const getInteractionCount = (interactions) => {
                    let unreadCount = 0;
                    interactions.forEach(function (interaction) {
                      if (interaction.unreadClientMessageCount) {
                        unreadCount += interaction.unreadClientMessageCount;
                      }
                    });
                    return unreadCount;
                  };

                  SFNotifications({
                    interactionId: response.data.interactionID,
                    token: this.props.token,
                    associateId: this.props.associateId,
                    unreadCount: getInteractionCount(this.props.interactions),
                  });
                }
                //if check for being on current Interaction
                if (
                  response.data.interactionID ===
                  this.props.currentInteraction.interactionId
                ) {
                  this.props.actions.mainAction(UPDATE_CHAT_LOG, {
                    data: response.data,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                    currentInteraction: this.props.currentInteraction,
                  });

                  //call PATCH Interactions to mark this as read
                  this.props.actions.patchInteractionReadSetting(
                    this.props.currentInteraction.associateId,
                    this.props.currentInteraction.interactionId,
                    this.props.currentInteraction.clientId,
                    0,
                    this.props.loggedInAssociateId
                  );
                  if (
                    response.data.sender.type !== "associate" &&
                    (this.props.messageDeliveryStatus !== "sending" ||
                      this.props.messageDeliveryStatus !== "delayed")
                  ) {
                    this.props.actions.mainAction(
                      UPDATE_MESSAGE_DELIVERY_STATUS,
                      response
                    );
                  }
                }
              }

              break;
            }
            case "officePreference": {
              //if check for restricting office settings to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                this.props.actions.mainAction(
                  GET_OFFICE_PREFERENCES,
                  response.data
                );
              }

              break;
            }
            /* case "messageDeliveryStatus": {
              //if check for restricting messages indicator to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                console.log("from app.js before calling MESSAGE_DELIVERY_STATUS: ", response);
                this.props.actions.mainAction(
                  MESSAGE_DELIVERY_STATUS,
                  response
                );
                if (
                  response.data.reports[0].status &&
                  response.data.reports[0].status === 5
                ) {
                  this.props.actions.logUIErrors(
                    response.data.agentID,
                    JSON.stringify(response),
                    "MessageDeliveryStatus WS",
                    false
                  );
                }
              }
              break;
            } */
            case "interaction_update": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.agentAssociateId
              ) {
                if (response.subEvents.includes("hide")) {
                  this.props.actions.mainAction(HIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unhide")) {
                  this.props.actions.mainAction(UNHIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("assignment")) {
                  this.props.actions.mainAction(ASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unassignment")) {
                  this.props.actions.mainAction(UNASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                }  else {
                  this.props.actions.mainAction(GET_INTERACTIONS, {
                    response: response,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                }
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      };
    }

    if (
      this.props.apiGatewayWebSocket &&
      this.props.apiGatewayWebSocket.readyState === wsReadyState.OPEN
    ) {
      this.props.apiGatewayWebSocket.onmessage = (payload) => {
        let response = JSON.parse(payload.data);
        if (!response.replay) {
          switch (response.type) {
            case "clientPreference": {
              this.props.actions.mainAction(GET_CUSTOMER_PREFERENCES, response);
              //To show Do not Text status bar if it changes in a WS push
              this.props.actions.mainAction(
                UPDATE_DO_NOT_TEXT_STATUS_BAR,
                response
              );
              break;
            }
            case "interaction_delete": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId ===
                  response.data.interaction.Item.ownerAgent.associateID
              ) {
                this.props.actions.mainAction(GET_INTERACTIONS, {
                  response: response,
                  currentOfficeMembers: this.props.currentOfficeMembers,
                });
                this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                  endlessScroll: true,
                });
              }
              break;
            }
            case "interaction": {
              //if check for restricting read/unread interactions to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                this.props.actions.mainAction(GET_INTERACTIONS, {
                  response: response,
                  currentOfficeMembers: this.props.currentOfficeMembers,
                });
                //If check New filter being selected and the incoming WS message is stating it is read, which means user clicked on a New message.
                //In order to avoid the interaction from disappearing from the New list, we remove the New filter selection so user can reply to the message without disruption.
                if (
                  this.props.filterOptions.MessageStatus === "Unread" &&
                  this.props.currentInteraction.isMessageViewed
                ) {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    name: "MessageStatus",
                    value: " ",
                    isNewlyRead: true,
                    endlessScroll: true,
                  });
                } else {
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
              }
              break;
            }
            case "message": {
              //if check for restricting messages to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.ownerAgent.associateID
              ) {
                //For customer-initiated messages, an extra call to get the customer detail needs to be made.
                let isNewInteraction = this.props.interactions.filter(
                  (interaction) =>
                    interaction.interactionId === response.data.interactionID ||
                    interaction.clientId === response.data.ownerClient.clientID
                );
                if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "client"
                ) {
                  this.props.actions.getClientInfoForNewClientInteraction(
                    response
                  );
                } else if (
                  isNewInteraction.length === 0 &&
                  response.data.ownerClient.type === "multi-match"
                ) {
                  this.props.actions.getMMInfoForNewClientInteraction(
                    response,
                  );
                } else {
                  this.props.actions.mainAction(UPDATE_INTERACTIONS, {
                    data: response.data,
                  });
                  this.props.actions.mainAction(CHANGE_FILTER_OPTIONS, {
                    endlessScroll: true,
                  });
                }
                if (response.data.sender.type !== "associate") {
                  const getInteractionCount = (interactions) => {
                    let unreadCount = 0;
                    interactions.forEach(function (interaction) {
                      if (interaction.unreadClientMessageCount) {
                        unreadCount += interaction.unreadClientMessageCount;
                      }
                    });
                    return unreadCount;
                  };

                  SFNotifications({
                    interactionId: response.data.interactionID,
                    token: this.props.token,
                    associateId: this.props.associateId,
                    unreadCount: getInteractionCount(this.props.interactions),
                  });
                }
                //if check for being on current Interaction
                if (
                  response.data.interactionID ===
                  this.props.currentInteraction.interactionId
                ) {
                  this.props.actions.mainAction(UPDATE_CHAT_LOG, {
                    data: response.data,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                    currentInteraction: this.props.currentInteraction,
                  });

                  //call PUT Interactions to mark this as read
                  this.props.actions.patchInteractionReadSetting(
                    this.props.currentInteraction.associateId,
                    this.props.currentInteraction.interactionId,
                    this.props.currentInteraction.clientId,
                    0,
                    this.props.loggedInAssociateId
                  );
                  if (
                    response.data.sender.type !== "associate" &&
                    (this.props.messageDeliveryStatus !== "sending" ||
                      this.props.messageDeliveryStatus !== "delayed")
                  ) {
                    this.props.actions.mainAction(
                      UPDATE_MESSAGE_DELIVERY_STATUS,
                      response
                    );
                  }
                  //if (response.data.sender.type !== "associate" ) {
                    /* this.props.actions.mainAction(
                      UPDATE_MESSAGE_DELIVERY_STATUS,
                      response
                    ); */
                  //}
                }
              }

              break;
            }
            case "officePreference": {
              //if check for restricting office settings to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                this.props.actions.mainAction(
                  GET_OFFICE_PREFERENCES,
                  response.data
                );
              }

              break;
            }
            /* case "messageDeliveryStatus": {
              //if check for restricting messages indicator to show up for the specific office the user is logged in
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.data.agentID
              ) {
                this.props.actions.mainAction(
                  MESSAGE_DELIVERY_STATUS,
                  response
                );
                if (
                  response.data.reports[0].status &&
                  response.data.reports[0].status === 5
                ) {
                  this.props.actions.logUIErrors(
                    response.data.agentID,
                    JSON.stringify(response),
                    "MessageDeliveryStatus WS",
                    false
                  );
                }
              }
              break;
            } */
            case "interaction_update": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.agentAssociateId
              ) {
                if (response.subEvents.includes("hide")) {
                  this.props.actions.mainAction(HIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unhide")) {
                  this.props.actions.mainAction(UNHIDE_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("assignment")) {
                  this.props.actions.mainAction(ASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else if (response.subEvents.includes("unassignment")) {
                  this.props.actions.mainAction(UNASSIGN_INTERACTION, {
                    interaction: response.data.interaction,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                } else {
                  this.props.actions.mainAction(GET_INTERACTIONS, {
                    response: response,
                    currentOfficeMembers: this.props.currentOfficeMembers,
                  });
                }
              }
              break;
            }
            case "text_template": {
              if (
                this.props.associateId !== "" &&
                this.props.associateId === response.agentAssociateId
              ) {
                response.data.websocketPushIndicator = true
                switch (response.operation) {
                  case "addTextTemplate": {
                    if (this.props.loggedUser !== response.data.createdBy){
                    this.props.actions.mainAction(ADD_CUSTOM_TEXT_TEMPLATES, response.data);
                    this.props.actions.mainAction(UPDATE_TEMPLATE_STATUS, { status: "" });
                    }
                    break;
                  }
                  case "updateTextTemplate": {
                    if (this.props.loggedUser !== response.data.updatedBy) {
                    this.props.actions.mainAction(UPDATE_CUSTOM_TEXT_TEMPLATES, response.data);
                    this.props.actions.mainAction(UPDATE_TEMPLATE_STATUS, { status: "" });
                  }
                    break;
                  }
                  case "deleteTextTemplate": {
                    this.props.actions.mainAction(DELETE_CUSTOM_TEXT_TEMPLATES, response.data);
                    this.props.actions.mainAction(UPDATE_TEMPLATE_STATUS, { status: "" });
                    break;
                  }
                  default: {
                    break;
                  }
                }
              }
              break;
            }
            default: {
              break;
            }
          }
        }
      };
    }
  }

  render() {
    if (this.state.loading) {
      return <SFConnectLogo animate="true" />;
    } else {
      return (
        <Router>
          <Header />
          <Switch>
            <Route
              path={sfc_routes.pages.moa}
              render={(props) => (
                <Moa {...props} webSocket={this.props.legacyWebSocket} />
              )}
            />
            <Route path={sfc_routes.pages.search} component={Search} />
            <Route path={sfc_routes.pages.error} component={GenericError} />
            <Route path={sfc_routes.pages.signup} component={Signup} />
            <Route path={sfc_routes.pages.gateway} component={Gateway} />
            <Route
              path={sfc_routes.pages.home}
              render={(props) => <Home {...props} />}
            />
          </Switch>
        </Router>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  token: state.authReducer.token,
  associateId: state.userInfoReducer.officeAssociateId,
  loggedUser: state.userInfoReducer.loggedInAssociateId,
  currentInteraction: state.interactionsReducer.currentInteraction,
  filterOptions: state.interactionsReducer.filterOptions,
  interactions: state.interactionsReducer.interactions,
  currentOfficeMembers: state.userInfoReducer.currentOfficeMembers,
  loggedInAssociateId: state.userInfoReducer.loggedInAssociateId,
  messageDeliveryStatus: state.interactionsReducer.messageDeliveryStatus,
  legacyWebSocket: state.webSocketReducer.legacyWebSocket,
  apiGatewayWebSocket: state.webSocketReducer.apiGatewayWebSocket,
  connectedToAPIGatewayWebSocket:
    state.webSocketReducer.connectedToAPIGatewayWebSocket,
  connectedToAPIAppsyncWebSocket:
    state.webSocketReducer.connectedToAPIAppsyncWebSocket,  
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        mainAction,
        getAssociateInformation,
        patchInteractionReadSetting,
        getClientInfoForNewClientInteraction,
        getMMInfoForNewClientInteraction,
        logUIErrors,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
